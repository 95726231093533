export const data = [
    {
        "fr": {
            "date": "10 Octobre 2024 à 19 h 00",
            "title": "Concert rencontre",
            "description": "A Reid hall Opéra Fuoco",
            
        },
        "linkHref": "https://operafuoco.fr/agenda/"
    },
    {
        "fr": {
            "date": "18 Octobre 2024 à 20 h 00",
            "title": "Tous à  l’opéra",
            "description": "avec Johan Farjot au théâtre Monsigny à Boulogne sur Mer",
            
        },
        "linkHref": "https://www.ville-boulogne-sur-mer.fr/decouvrir-et-sortir/agenda-des-manifestations/tout-l-agenda/item/2667-tous-a-l-opera-2"
    },
    {
        "fr": {
            "date": "26 et 27 Octobre 2024",
            "title": "Et Op!",
            "description": `Spectacle pour enfants à l’Opéra de Reims avec les musiciens de l’orchestre de Reims
Conception artistique, direction musicale Christophe Mirambeau.

Mise en scène Pascal Neyron

Scénographie Casilda Desazars
            `,
            
        },
        "linkHref": "https://operadereims.com/event/et-op-24-25-1/"
    },
    {
        "fr": {
            "date": "1er Décembre 2024",
            "title": "Et Op!",
            "description": "Reprise à l’opéra de Reims",
            
        },
        "linkHref": "https://operadereims.com/event/et-op-24-25-1/"
    },
    {
        "fr": {
            "date": "18 Décembre 2024 à 19 h 00",
            "title": "Opéra Fuoco",
            "description": "Concert Apéro Massenet Opéra de Massy",
            
        },
        "linkHref": "https://operafuoco.fr/apero-concert-massenet/"
    },
    {
        "fr": {
            "date": "Juin 2024",
            "title": "L’impressionnisme et musique",
            "description": "Musiques d’Emmanuel Chabrier et André Messager.\n\t\t\t\tAuditorium du Musée d’Orsay.\n\t\t\t\tOrchestre des Frivolités Parisiennes"
        },
        "linkHref": "https://lesfrivolitesparisiennes.com/spectacle/1874-impressionnisme-et-musique/"
    },
    {
        "fr": {
            "date": "22 mai 2024 à 19h00",
            "title": "Récital d’été",
            "description": "Les Saisons de l’Académie. Opéra Comique. Foyer Favart\n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/les-saisons-de-l-academie"
    },
    {
        "fr": {
            "date": "2, 3 et 4 mai 2024",
            "title": "La Princesse et le bouffon",
            "description": "Les Pléiades, autour de Fantasio, académie de l’Opéra Comique.\n\t\t\t\t\n2 mai à 14h30, séance scolaire \n\t\t\t\t\n3 mai à 10h00, séance scolaire \n\t\t\t\t\n4 mai 2024 à 15h00 \n\t\t\t\t\nSalle Bizet, Opéra Comique \n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/la-princesse-et-le-bouffon"
    },
    {
        "fr": {
            "date": "23 Mars 2024 à 20h00",
            "title": "Voyage au Pays de Maurice Ravel",
            "description": "Salle Favart de  Opéra Comique. \nConcert de l’Académie de l’opéra Comique. \nLouis Langrée, direction.\nOrchestre de Chambre de Paris.\n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/voyage-au-pays-de-maurice-ravel"
    },
    {
        "fr": {
            "date": "22 Mars 2024 à 19h30",
            "title": "Maestro !",
            "description": "Répétition ouverte du Voyage au Pays de Maurice Ravel avec Louis Langrée\n\t\t\t\tSalle Favart de l’opéra Comique."
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/maestro"
    },
    {
        "fr": {
            "date": "MARS 2024",
            "title": "Cover Stéphanie D’Oustrac, l’Heure Espagnole Ravel (rôle de Concepcion)",
            "description": "Opéra Comique, Louis Langrée, mise en scène Guillaume Gallienne"
        }
    },
    {
        "fr": {
            "date": "14 mars 2024 à 19h00",
            "title": "Récital de Printemps",
            "description": "Les Saisons de l’Académie. Opéra Comique. Foyer Favart\n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/les-saisons-de-l-academie"
    },
    /*{
        "fr": {
            "date": "Samedi 2 Mars 2024",
            "title": "Journée Mozart",
            "description": "Anne Le Bozec, pianiste \n\nThéâtre du Capitole de Toulouse\n\t\t\t\t"
        },
        "linkHref": "https://opera.toulouse.fr/agenda/concerts/journee-mozart/"
    },
    {
        "fr": {
            "date": "10 février  2024",
            "title": "Belboul, Massenet / Modaresifar, Création mondiale ",
            "description": "Opéra de Reims.\nRôle de Zaï-za (pupille d’Ali Bazar).\nLes Frivolités Parisiennes, mise en scène et conception Alexandra Lacroix"
        },
        "linkHref": "https://lesfrivolitesparisiennes.com/spectacle/ladorable-belboul/"
    },
    {
        "fr": {
            "date": "12 décembre 2023 à 19h00",
            "title": "Récital d’Hiver",
            "description": "Les Saisons de l’Académie. Opéra Comique. Foyer Favart \n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/les-saisons-de-l-academie"
    },
    {
        "fr": {
            "date": "22 Decembre 2023",
            "title": "Récital Lyrique Génération Opéra",
            "description": "Avec Rodolphe Lospied, pianiste et Yara Kasti, soprano\n\nThéâtre d’Aubagne\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "2 décembre 2023",
            "title": "L’arrière-Pays, Opéra de Reims. Au fil de l’Eau",
            "description": "Mélodies pour mezzo soprano, harpe et dispositif électro-acoustique (dir.musicale et création Thomas Nguyen)\nAnnabelle Jarre, harpiste"
        },
        "linkHref": "https://www.operadereims.com/spectacle/larriere-pays/"
    },
    {
        "fr": {
            "date": "28 & 29 novembre 2023 à 19h00 et 20h30",
            "title": "L’Amour à la française",
            "description": "Récital Lyrique hors les murs de l’académie de l’Opéra Comique \nGrande Nef du Musée d’Orsay \n\nDirection musicale et préparation des solistes Hervé Niquet • Mise en scène Héloïse Sérazin\nCollaboration avec le Palazetto Bru Zane\n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/l-amour-a-la-francaise-au-musee-d-orsay"
    },
    {
        "fr": {
            "date": "15 novembre à 19h00",
            "title": "Récital d’Automne",
            "description": "Les Saisons de l’académie Opéra Comique\n\t\t\t\t\n16 novembre 2023 à 10H30 et 14H (scolaires)\nFoyer Favart \n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/les-saisons-de-l-academie"
    },
    {
        "fr": {
            "date": "29 Octobre 2023",
            "title": "Récital lyrique « Il était une fois l’opéra »",
            "description": "Avec Ayano Kamei, pianiste et Margaux Poguet, Soprano \n\nConcert « Au fil des Saisons »\nMusique en Périgord \nEn partenariat avec le Festival Ravel \n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "19, 20, 21 octobre à 19h00",
            "title": "L’Amour à la Française",
            "description": "Spectacle lyrique de l’Académie de l’Opéra Comique Salle Bizet \n\t\t\t\t\nDirection musicale et préparation des solistes Hervé Niquet • Mise en scène Héloïse Sérazin\nCollaboration avec le Palazetto Bru Zane\n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/l-amour-a-la-francaise"
    },

    {
        "fr": {
            "date": "29 septembre & 5 octobre à 22h20",
            "title": "Nocturnes du spectacle de  la Fille de Mme Argot",
            "description": "Postlude musical salle Bizet"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/nocturne-la-fille-de-madame-angot"
    },
    {
        "fr": {
            "title": "Masterclasses  publiques de l’Académie de l’Opéra Comique 23/24",
            "description": "27 septembre 2023 : Richard Brunel\n\n29 septembre 2023 : Hervé Niquet\n\n2 octobre 2023 : Véronique Gens\n\n20 décembre 2023 : Franck Leguérinel \n\n6 février 2024 : Stéphane Degout\n\n27 février 2024 : Stéphanie d’Oustrac \n\n7 mars 2024 : Guillaume Gallienne\n\t\t\t\t"
        },
        "linkHref": "https://www.opera-comique.com/fr/spectacles/masterclasse-de-l-academie"
    },
    {
        "fr": {
            "date": "1 septembre 2023",
            "title": "Nuits d'été de Berlioz, avec Karine Deshayes",
            "description": "Festival Ravel, Orchestre OPPB direction Fayçal Karoui, théâtre de Bayonne"
        },
        "linkHref": "https://festivalravel.fr/evenements/orchestre-de-pau-pays-de-bearn-karine-deshayes-mezzo-soprano-marion-vergez-pascal-mezzo-soprano-faycal-karoui-direction/"
    },
    {
        "fr": {
            "date": "15 Juillet 2023",
            "title": "Récital lyrique « Il était une fois l’opéra »",
            "description": "Avec Ayano Kamei, pianiste et Margaux Poguet, Soprano \n\nFestival Lyrique de Soustons / Opéra des Landes \nEn partenariat avec le Festival Ravel \n\t\t\t\t"
        }
    },
    {
        "fr": {
            "title": "Samedi 3 Juin 2023 à 20h00 Espace St Jean de Melun.\n\nDimanche 18 Juin 2023 à 17h00 Collégiale  de Provins.\n\nSamedi 24 juin à 16h30 Cathédrale  de Sens.\n\n\t\t\t\t",
            "description": "Mezzo-soprano solo du Stabat Mater de Dvořák\n\t\t\t\t\nSoprano : Armelle Debos.\n\nChœur Marc-Antoine Charpentier, la Chorale du Montois et du Provinois et l’Orchestre Symphonique en Résonnance (dir. Frédéric Baudry)\n\t\t\t\t"
        },
        "linkHref": "https://culturetvous.fr/informations-transversales/agenda/stabat-mater-danton-dvorak-4603"
    },
    {
        "fr": {
            "date": "22 Juin 2023",
            "title": "Au fil de l'eau",
            "description": "Opéra de Reims\n\nAnnabelle Jarre, harpe\n\nThomas N’guyen, composition et direction musicale \n\nCollectif Io\t\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "27,28 à 20h et 29 avril 2023 à 18h",
            "title": " Mezzo-soprano du Requiem de Mozart",
            "description": "Chœur et Orchestre de Pau Pays de Béarn (Oppb)\n\t\t\t\t\nArmelle Khourdoïan, Soprano\n\nMarion Vergez-Pascal, Alto\n\nPierre-Antoine Chaumien, Ténor\n\nMatthieu Lécroart, Baryton\n\nPascale Verdier, Chœur OPPB\n\nDirection : Fayçal Karoui \n\t\t\t\t"
        },
        "linkHref": "https://www.oppb.fr/armelle-khourdoian-avril-2023/"
    },
    {
        "fr": {
            "date": "7 Mars 14h30, les 8 Mars 10 Mars & 11 Mars à 20h",
            "title": "Didon et Enée de Purcell. Rôle de l’Enchanteresse",
            "description": "Direction : Leonardo Garcia Alarcón;\n\nMise en scène : Marc Lainé;\n\nOrchestre du Conservatoire de Paris;\n\nCoproduction Conservatoire National de Paris et Philarmonie de Paris \n\t\t\t\t"
        },
        "linkHref": "https://philharmoniedeparis.fr/fr/activite/opera/23989-henry-purcell-didon-et-enee"
    },
    {
        "fr": {
            "date": "Jeudi 2 Février 20 h 00",
            "title": "Cathédrale St Louis des Invalides",
            "description": "Concert du gouverneur militaire de Paris.\n\nMezzo-Soprano solo du Dixit Dominus Haendel.\n\nEnsemble vocal de l’Ecole polytechnique.\nOrchestre des Troupes de Marine (dir. Grégoire Michaud)\n\n\t\t\t\t"
        },
        "linkHref": "https://www.helloasso.com/associations/association-pour-le-developpement-des-uvres-d-entraide-dans-l-armee-ado/evenements/concert-du-gouverneur-militaire-de-paris-2-fevrier-2023"
    },
    {
        "fr": {
            "date": "Samedi 7 Janvier 2023, 20h00",
            "title": "Récital lyrique avec Margaux Poguet et Ayano Kamei au piano",
            "description": "Salle du concert des Carmes de La Rochefoucault, \ndans le cadre des concerts en diffusion de l’académie Ravel\n\t\t\t\t"
        },
        "linkHref": "https://festivalravel.fr/evenements/concert-de-margaux-poguet-soprano-marion-vergez-pascal-mezzo-soprano-et-ayano-kamei-piano/"
    },
    {
        "fr": {
            "date": "Vendredi 16 Décembre 2022 à 21 h à l'église de la Madeleine",
            "title": "Alto solo du Requiem de Mozart",
            "description": "Avec le choeur et l’orchestre de l’académie du Palais Royal.\n\nDirection Jean-Philippe Sarcos.\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "Mardi 13 décembre 2022 à 20h30",
            "title": "L’isola Disabitata de Haydn, Costanza",
            "description": "Théâtre Traversière (Paris 12ème)\n\nLisa Chaïb, Silvia\n\nJordan Mouiassia, Gernando\n\nOlivier Cesarini, Enrico\n\nVincent Laissy, direction musicale \n\nLe studi’opera\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "Dimanche 11 Décembre 2022 à 15 h à l'église Saint-François-de-Sales",
            "title": "Alto solo du Requiem de Mozart",
            "description": "Avec le choeur et l’orchestre de l’académie du Palais Royal.\n\nDirection Jean-Philippe Sarcos.\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "Jeudi 8 décembre à 21 h à l'église Saint-Eustache",
            "title": "Alto solo du Requiem de Mozart",
            "description": "Avec le choeur et l’orchestre de l’académie du Palais Royal.\n\nDirection Jean-Philippe Sarcos.\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "Samedi 3. Décembre 20h30 & Dimanche 4 décembre 16h00",
            "title": "Le Messie de Haendel",
            "description": "Mezzo-soprano solo\n\nChœur et orchestre de St Germain en Laye\n\nDirection Alexandre Korovitch \n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "19 novembre 2022",
            "title": "Concert autour de la vie de Rosa Bonheur",
            "description": "château de Fontainebleau\n\nKaëlig Boché, ténor\n\nThomas Tacquet Pianiste\n\nShanee Krön comédienne"
        }
    },
    {
        "fr": {
            "date": "Samedi 11 novembre à 19h30",
            "title": "Concert au Schloss Leopoldskron, Salzburg",
            "description": "Open your Eyes ; (Iain Burnside - RIAM) en coproduction avec le CNSM de Paris, la Royal Irish Academy of Music, la Guildhall School of Music and Drama of London, et le Schloss Leopoldskron of Salzburg.\nProgramme de mélodies et lieder (Chausson, Mahler et Schubert) \n\t\t\t\t"
        },
        "linkHref": "https://www.riam.ie/news/iain-burnside-open-your-eyes-and-tell-me-what-you-see"
    },
    {
        "fr": {
            "date": "Samedi 9 novembre (heure à déterminer)",
            "title": "Concert à l’ambassade d’Irlande à Paris",
            "description": "Open your Eyes ; (Iain Burnside - RIAM) en coproduction avec le CNSM de Paris, la Royal Irish Academy of Music, la Guildhall School of Music and Drama of London, et le Schloss Leopoldskron of Salzburg.\nProgramme de mélodies et lieder (Chausson, Mahler et Schubert) \n\t\t\t\t"
        },
        "linkHref": "https://www.riam.ie/news/iain-burnside-open-your-eyes-and-tell-me-what-you-see"
    },
    {
        "fr": {
            "date": "Samedi 7 novembre à 18h00",
            "title": "Concert au Milton Court Concert Hall (Guildhall School of Music and Drama)",
            "description": "Open your Eyes ; (Iain Burnside - RIAM) en coproduction avec le CNSM de Paris, la Royal Irish Academy of Music, la Guildhall School of Music and Drama of London, et le Schloss Leopoldskron of Salzburg.\nProgramme de mélodies et lieder (Chausson, Mahler et Schubert) \n\t\t\t\t"
        },
        "linkHref": "https://www.riam.ie/news/iain-burnside-open-your-eyes-and-tell-me-what-you-see"
    },
    {
        "fr": {
            "date": "Samedi 5 novembre à 19h00",
            "title": "Concert au Stephen Vernon Studio de Dublin (Royal Irish Academy of Music)",
            "description": "Open your Eyes ; (Iain Burnside - RIAM) en coproduction avec le CNSM de Paris, la Royal Irish Academy of Music, la Guildhall School of Music and Drama of London, et le Schloss Leopoldskron of Salzburg.\nProgramme de mélodies et lieder (Chausson, Mahler et Schubert) \n\t\t\t\t"
        },
        "linkHref": "https://www.riam.ie/news/iain-burnside-open-your-eyes-and-tell-me-what-you-see"
    },
    {
        "fr": {
            "date": "16 AU 18 SEPTEMBRE",
            "title": "Au fil de l'eau",
            "description": "Création sonore de Thomas N’guyen pour mezzo-soprano, harpe, et casques à Obernai"
        }
    },
    {
        "fr": {
            "date": "10 septembre de 14h à 17h",
            "title": "Concert et remise des prix",
            "description": "Chapiteau Hariet Baita de St Jean de Luz"
        },
        "linkHref": "https://festivalravel.fr/academie/"
    },
    {
        "fr": {
            "date": "7 septembre 2022 à 21h",
            "title": "Église St Esprit",
            "description": "Festival du Paséo de Biarritz"
        },
        "linkHref": "https://festivalravel.fr/academie/"
    },
    {
        "fr": {
            "date": "5 septembre 2022 à 21h",
            "title": "Église St Esprit du Festival Paséo de Biarritz",
            "description": ""
        },
        "linkHref": "https://festivalravel.fr/academie/"
    },
    {
        "fr": {
            "date": "4 septembre 2022, à 12h, 15h & 18h",
            "title": "Villa Arnagz d’Arcangues",
            "description": ""
        },
        "linkHref": "https://festivalravel.fr/academie/"
    },
    {
        "fr": {
            "date": "3 septembre 2022 à 12h",
            "title": "Gymnase Urdazuri",
            "description": ""
        },
        "linkHref": "https://festivalravel.fr/academie/"
    },
    {
        "fr": {
            "date": "2 septembre 2022 à 21h",
            "title": "centre social Sagardian de St Jean de Luz",
            "description": ""
        },
        "linkHref": "https://festivalravel.fr/academie/"
    },
    {
        "fr": {
            "date": "31 août 2022 à 21h",
            "title": "Festival Paséo",
            "description": "Église St Esprit de Bayonne"
        },
        "linkHref": "https://festivalravel.fr/academie/"
    },
    {
        "fr": {
            "date": "27 août au 10 Septembre",
            "title": "Académie Ravel de St-Jean de Luz",
            "description": "Masterclasses publiques quotidiennes avec Karine Deshayes, Jean-Philippe Lafont et Sabien Vatin"
        }
    },
    {
        "fr": {
            "date": "27 AU 31 JUILLET",
            "title": "Au fil de l'eau",
            "description": "Création sonore de Thomas N’guyen pour mezzo-soprano, \n\t\t\t\tharpe, et casques. Dans le cadre du Grand Est’ival (Meuse).\n\t\t\t\t"
        },
        "linkHref": "https://grandestival.fr/"
    },
    {
        "fr": {
            "title": "Festival des Chapelles",
            "date": "26 à Sabran et 27 juillet à Goudargues 21h",
            "description": "« Mignonne allons voir si la rose » \nProgramme a capella avec le Quatuor Bonelli \n\nFanny Soyer, Soprano\n\nMarco Van Baaren, ténor\n\nAdrien Fournaison, baryton\n\t\t\t\t"
        },
        "linkHref": "https://festivaldeschapelles.com/programme-2022/"
    },
    {
        "fr": {
            "date": "18, 20 & 25 JUIN, 16 H 00",
            "title": "Mini-concerts autour des Fables de Lafontaine",
            "description": "Saint-Saëns, Aboulker, Offenbach, et bien d’autres ... \nAvec les musiciens de l’Orchestre de Chambre de Paris\n\n\t\t\t\t"
        },
        "linkHref": "https://www.orchestredechambredeparis.com/action/mini-concerts-dans-les-centres-dhebergement-durgence/"
    },
    {
        "fr": {
            "date": "Lundi 23 Juin à 21h",
            "title": "Château Rosa Bonheur, Thomery",
            "description": "Récital  « Rosa Bonheur, dame nature »Soirée d'ouverture du festival Emmenez-moi (en partenariat avec l'ONF).\nBastien Rimondi, ténor.\nThomas Taquet, pianiste.\n\t\t\t\t"
        },
        "linkHref": "https://www.seineetmarnevivreengrand.fr/actualites/festival-emmenez-moi-du-24-juin-au-10-juillet-2022/"
    },
    {
        "fr": {
            "date": "Lundi 20 Juin à 20h",
            "title": "Récital de mélodies françaises",
            "description": "Temple de Neuilly-sur-Seine.\nSimon Adda Reyss, piano.\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "Dimanche 19 Juin à 17h",
            "title": "Concert à Notre Dame de Bethune",
            "description": "Maitrise de Notre-Dame de Paris;\nDirection Henri Chalet\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "Samedi 18 Juin à 20h",
            "title": "Requiem de Mozart ( Temple de L’Oratoire du Louvre )",
            "description": "Mezzo-soprano solo; \nChoeur et orchestre de l’Oratoire du Louvre ;\nAlexandre Korovitch, direction.\n\t\t\t\t"
        },
        "linkHref": "https://www.fnacspectacles.com/place-spectacle/ticket-evenement/musique-sacree-requiem-de-mozart-man31590-lt.htm"
    },
    {
        "fr": {
            "date": "JEUDI 11 JUIN, 20 H 30",
            "title": "Mezzo-solo de la Missa Solemnis K.139 (messe de l'orphelinat) de Mozart ",
            "description": "Orchestre symphonique kimbanguiste de Kinshasa.\nChoeur de l'Orchestre de Paris.\nChoeur de jeunes de l'Orchestre de Paris.\nArmand Diangienda W., direction.\nGrande salle Pierre Boulez de la Philharmonie.\n\t\t\t\t"
        },
        "linkHref": "https://philharmoniedeparis.fr/fr/activite/concert-symphonique/22808-orchestre-symphonique-kimbanguiste-de-kinshasa"
    },
    {
        "fr": {
            "date": "JEUDI 9 JUIN, 19 H",
            "title": "Au fil de l'eau",
            "description": "Création sonore de Thomas N’guyen pour mezzo-soprano, \n\t\t\t\tharpe, et casques."
        },
        "linkHref": "https://www.unidivers.fr/event/au-fil-de-leau-abbeville-somme-2022-06-09/amp/"
    },
    {
        "fr": {
            "date": "VENDREDI 3 JUIN, 19 H",
            "title": "Pas si bête",
            "description": "D'après Le souriceau stupide, op.56 de Dmitri Chostakovitch.\n\nProjet pédagogique \n\nChoeurs et oeuvres de musique russe Dramaturgie, conception et mise en scène Plein jour.\nDirection musicale Franck Krawczyk.\nThéâtre des Bouffes du Nord.\n\t\t\t\t"
        },
        "linkHref": "https://www.bouffesdunord.com/fr/la-saison/pas-si-bete"
    },
    {
        "fr": {
            "date": "SAMEDI 14 & DIMANCHE 15 MAI, 16 H & 17 H",
            "title": "Concert récital pour les tout-petits et leurs parents",
            "description": "Dans le cadre des concerts pour mélomanes en culottes courtes du Palais Royal.\n\nLycée Buffon\n\t\t\t\t"
        },
        "linkHref": "https://le-palaisroyal.com/concert/melomanes-en-culotte-courte"
    },
    {
        "fr": {
            "date": "MERCREDI 13, JEUDI 14 et VENDREDI 15 AVRIL",
            "title": "Le Parlement des Reines",
            "description": "Spectacle de Emmanuelle Cordoliani.\nAtelier lyrique du CNSM de Paris.\nDirection musicale : Yann Molénat.\nSalle Fleuret du Conservatoire National Supérieur de Paris.\n\t\t\t\t"
        }
    },
    {
        "fr": {
            "date": "VENDREDI 11 & SAMEDI 12 MARS, 20h30",
            "title": "Mezzo solo du Requiem de Mozart ",
            "description": "Avec Clémence Danvy, Mathys Lagier, et Adrien Fournaison\nChœur et orchestre en formation philharmonique du COGE (Choeur et orchestre des Grandes Écoles)\nDirection :  Alizė Léhon\n\t\t\t\t"
        },
        "linkHref": "https://mgecoge.org/la-saison"
    },
    {
        "fr": {
            "title": "Là - Haut de Maurice Yvain, opéra bouffe en 3 actes",
            "description": "Une des élues(Sterling). Orchestre des Frivolités Parisiennes\n\n22 fev. 2022 | Théâtre Impérial – Opéra de Compiègne,\n6 mars 2022 | Théâtre Raymond Devos de Tourcoing,\nDu 18 au 31 mars 2022 | Théâtre de l’Athénée Louis-Jouvet\n\t\t\t\t"
        },
        "linkHref": "https://lesfrivolitesparisiennes.com/spectacle/la-haut/"
    },
    {
        "fr": {
            "title": "Récital lyrique autour de Carmen de Bizet",
            "description": "Théâtre Traversière\n\nAdaptation par le Studiopera.\nAvec Jean Goyetche (ténor) et Vincent Laissy (Piano, direction de chant).\n\t\t\t\t",
            "date": "16 FEVRIER 2022,\n\n18 h 30\n\t\t\t"
        },
        "linkHref": "https://www.theatre-traversiere.fr/recital-lyrique?iframe=true&category=apero- spectacle"
    },
    {
        "fr": {
            "title": "L’affaire Clemenza",
            "description": "Adaptation de Franck Krawczyk de La Clémence de Titus de Mozart Rôle d’Annio.\nCompanie Plein Jour\n\t\t\t\t",
            "date": "4 FEVRIER 2022,\n\n20 h 30\n\t\t\t"
        },
        "linkHref": "http://pleinjour.com/laffaire-clemenza/"
    },
    {
        "fr": {
            "title": " Récital lyrique Deux hommes et une femme",
            "description": "Théâtre de Lunéville\n\nAvec Mathys Lagier et Adrien Fournaison Version piano avec Orlando Bass.\n\t\t\t\t",
            "date": "8 JANVIER 2022,\n\n20 h 30\n\t\t\t"
        },
        "linkHref": "https://billetterie-theatre-luneville.tickandlive.com/reserver/deux-hommes-et-une-femme/7347"
    }*/
];